var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.update")) + " - " + _vm._s(_vm.currentUser.name) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('form', {
    staticClass: "login-form mt-4",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_c('div', {
    staticClass: "card bg-light border-0 rounded shadow",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3"
  }, [_c('h4', {
    staticClass: "title text-center",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.user")) + " ")]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-3 mt-4 mt-sm-0 text-center"
  }, [_vm.profile_picture ? _c('div', [_c('div', {
    staticClass: "bg-light shadow",
    staticStyle: {
      "border": "1px solid #eee",
      "position": "relative",
      "display": "inline-block",
      "border-radius": "100%",
      "height": "150px",
      "width": "150px",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "background-position": "center center"
    },
    style: 'background-image: url(' + _vm.profile_picture + ')'
  }, [_c('span'), _vm._m(0), _c('input', {
    staticClass: "form-control-file",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  })])]) : _vm._e()]), _c('div', {
    staticClass: "col-md-9"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.designation,
      expression: "form.designation"
    }],
    staticClass: "form-control",
    staticStyle: {
      "border-bottom-right-radius": "0",
      "border-top-right-radius": "0"
    },
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "designation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeDesignation]
    }
  }, _vm._l(_vm.designations, function (designation) {
    return _c('option', {
      key: designation,
      domProps: {
        "value": designation
      }
    }, [_vm._v(" " + _vm._s(designation) + " ")]);
  }), 0)]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "full_name"
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), !_vm.$v.form.name.minLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name-atleast")) + " " + _vm._s(_vm.$v.form.name.$params.minLength.min) + " " + _vm._s(_vm.$t("characters")) + " ")]) : _vm._e(), !_vm.$v.form.name.maxLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name-max")) + " " + _vm._s(_vm.$v.form.name.$params.maxLength.max) + " " + _vm._s(_vm.$t("characters")) + " ")]) : _vm._e(), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.ic_number,
      expression: "form.ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "ic_number",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "ic_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("gender")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.gender,
      expression: "form.gender"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "gender"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "gender", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(" -- " + _vm._s(_vm.$t("choose.gender")) + " -- ")]), _vm._l(_vm.genders, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(gender.split(":")[1]) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.mobile_number,
      expression: "form.mobile_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "mobile_number"
    },
    domProps: {
      "value": _vm.form.mobile_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "mobile_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("office-no")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.office_phone_number,
      expression: "form.office_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": ""
    },
    domProps: {
      "value": _vm.form.office_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "office_phone_number", $event.target.value);
      }
    }
  })])])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.staff_code.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("staff-code")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.staff_code.$model,
      expression: "$v.form.staff_code.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Kod Kakitangan",
      "name": ""
    },
    domProps: {
      "value": _vm.$v.form.staff_code.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.staff_code, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.staff_code.$error && !_vm.$v.form.staff_code.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("staff-code")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("staff-no")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.staff_number,
      expression: "form.staff_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "No Pekerja",
      "name": ""
    },
    domProps: {
      "value": _vm.form.staff_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "staff_number", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("address")))]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.address,
      expression: "form.address"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "address",
      "rows": "2"
    },
    domProps: {
      "value": _vm.form.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "address", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.postcode,
      expression: "form.postcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.form.postcode
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "postcode", $event.target.value);
      }, _vm.getPostcode]
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])]), _vm.userRole != 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("email")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "email"
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.form.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()])])]) : _vm._e()])]), _c('div', {
    staticClass: "row align-items-center mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.status.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("user-status")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.status.$model,
      expression: "$v.form.status.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("- " + _vm._s(_vm.$t("select-status")) + " -")]), _c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.form.status.$error && !_vm.$v.form.status.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("user-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("email")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "email"
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.form.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e(), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("username")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.currentUser.username
    }
  })])])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative text-left"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": "/profile"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left"
  }), _vm._v(" " + _vm._s(_vm.$t("back")) + " ")])], 1)]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])])])])])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "title": _vm.$t('add-newpostcode'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.storePostcode
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.postcode,
      expression: "formPostcode.postcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostcode.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.city,
      expression: "formPostcode.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.state,
      expression: "formPostcode.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "state", $event.target.value);
      }
    }
  })])])])])], 1)])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "shadow",
    staticStyle: {
      "background": "white",
      "border-radius": "5px",
      "padding": "4px",
      "position": "absolute",
      "bottom": "5px",
      "right": "5px",
      "margin": "0"
    },
    attrs: {
      "for": "upload-photo"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/image-gallery.png",
      "height": "20",
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }